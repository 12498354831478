import classNames from 'classnames';
import Image from 'next/image';

export function Avatar(props: AvatarProps) {
  const img =
    'children' in props ? (
      props.children
    ) : (
      <Image
        src={props.src || '/avatar-not-logged.webp'}
        alt="Avatar"
        fill
        className="object-contain"
      />
    );

  return (
    <div
      className={classNames(
        'relative rounded-full bg-white overflow-hidden flex justify-center items-center relative border border-neutral-100',
        avatarSize(props.size),
        props.className
      )}
    >
      {img}
    </div>
  );
}

export function avatarSize(size: AvatarSize) {
  switch (size) {
    case '3xl':
      return 'h-[168px] w-[168px]';
    case '2xl':
      return 'h-[136px] w-[136px]';
    case 'xl':
      return 'h-[80px] w-[80px]';
    case 'lg':
      return 'h-12 w-12';
    case 'xs':
      return 'w-6 h-6';
    case 'md':
      return 'w-10 h-10';
    default:
      return 'h-[32px] w-[32px]';
  }
}

export type AvatarProps = SrcAvatarProps | ImageAvatarProps;

export interface SrcAvatarProps {
  src: string | null | undefined;
  size: AvatarSize;
  className?: string;
}

export interface ImageAvatarProps {
  children: React.ReactNode;
  size: AvatarSize;
  className?: string;
}

export type AvatarSize = '3xl' | '2xl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs';
