import React from 'react';
import { IllustrationsProps } from '../Illustrations.interface';

export const MapPinIllustration: React.FC<IllustrationsProps> = ({
  className,
}) => (
  <svg
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M12.25 6.83325C12.25 10.9166 7 14.4166 7 14.4166C7 14.4166 1.75 10.9166 1.75 6.83325C1.75 5.44087 2.30312 4.10551 3.28769 3.12094C4.27226 2.13638 5.60761 1.58325 7 1.58325C8.39239 1.58325 9.72774 2.13638 10.7123 3.12094C11.6969 4.10551 12.25 5.44087 12.25 6.83325Z"
      fill="white"
    />
    <path
      d="M7 8.58325C7.9665 8.58325 8.75 7.79975 8.75 6.83325C8.75 5.86675 7.9665 5.08325 7 5.08325C6.0335 5.08325 5.25 5.86675 5.25 6.83325C5.25 7.79975 6.0335 8.58325 7 8.58325Z"
      fill="white"
    />
    <path
      d="M12.25 6.83337C12.25 10.9167 7 14.4167 7 14.4167C7 14.4167 1.75 10.9167 1.75 6.83337C1.75 5.44099 2.30312 4.10563 3.28769 3.12106C4.27226 2.1365 5.60761 1.58337 7 1.58337C8.39239 1.58337 9.72774 2.1365 10.7123 3.12106C11.6969 4.10563 12.25 5.44099 12.25 6.83337Z"
      stroke="url(#paint0_linear_2752_25571)"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 8.58337C7.9665 8.58337 8.75 7.79987 8.75 6.83337C8.75 5.86688 7.9665 5.08337 7 5.08337C6.0335 5.08337 5.25 5.86688 5.25 6.83337C5.25 7.79987 6.0335 8.58337 7 8.58337Z"
      stroke="url(#paint1_linear_2752_25571)"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="6.9999"
      cy="6.6"
      r="2.6"
      fill="url(#paint2_linear_2752_25571)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2752_25571"
        x1="1.75"
        y1="3.13222"
        x2="13.2468"
        y2="4.80671"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2CBC32" />
        <stop offset="0.98762" stopColor="#07996E" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2752_25571"
        x1="1.75"
        y1="3.13222"
        x2="13.2468"
        y2="4.80671"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2CBC32" />
        <stop offset="0.98762" stopColor="#07996E" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2752_25571"
        x1="4.3999"
        y1="4.62759"
        x2="10.0358"
        y2="5.63084"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2CBC32" />
        <stop offset="0.98762" stopColor="#07996E" />
      </linearGradient>
    </defs>
  </svg>
);
