// When adding new language, add language json to `messages` in `_app.tsx` file.
"use strict";
const locales = [
    "en",
    "pl"
];
const defaultLocale = "en";
module.exports = {
    locales,
    defaultLocale
};
