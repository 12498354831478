import React from 'react';
import classNames from 'classnames';
import { toast, Toast } from 'react-hot-toast';

interface ToastWrapperProps {
  children: React.ReactNode;
  t: Toast;
  className?: string;
}

export const ToastWrapper: React.FC<ToastWrapperProps> = ({
  children,
  t,
  className,
}) => {
  return (
    <div
      className={classNames(
        'max-w-md min-w-[340px] shadow-lg rounded-xl pointer-events-auto flex relative overflow-hidden',
        {
          'animate-enter': t.visible,
          'animate-leave': !t.visible,
        },
        className
      )}
    >
      {children}
      <div className="flex border-l border-neutral-100">
        <button
          onClick={() => toast.dismiss(t.id)}
          className={classNames(
            'w-full border border-transparent rounded-none p-4 flex items-center justify-center text-sm font-medium focus:outline-none',
            className
          )}
        >
          OK
        </button>
      </div>
    </div>
  );
};
