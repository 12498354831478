// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import ReactGA from "react-ga4";
export const useAnalytics = ()=>{
    const ga4 = (eventName, opts)=>{
        var ref;
        ReactGA === null || ReactGA === void 0 ? void 0 : ReactGA.event(eventName, {
            category: "custom",
            page_location: window === null || window === void 0 ? void 0 : (ref = window.location) === null || ref === void 0 ? void 0 : ref.href,
            ...opts
        });
    };
    return {
        onButtonClick: (button_name)=>{
            ga4("button_click", {
                button_name
            });
        },
        onModalOpen: (modal_name, opt)=>{
            ga4("modal_open", {
                modal_name,
                ...opt
            });
        },
        onVideoPopupOpen: (video_title, opt)=>{
            ga4("video_map_popup_open", {
                video_title,
                ...opt
            });
        },
        onVideoPlay: (video_title, opt)=>{
            ga4("video_play", {
                video_title,
                ...opt
            });
        },
        onSignIn: ()=>{
            ga4("sign_in");
        },
        onLiveNowStart: ()=>{
            ga4("live_now_started");
        },
        onVideoSubmit: ()=>{
            ga4("video_submitted");
        },
        onVideoDraftSubmit: ()=>{
            ga4("video_draft_submitted");
        },
        onCreatePlanner: ()=>{
            ga4("planner_created");
        }
    };
};
