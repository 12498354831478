import React from 'react';
import { InformationOutlineIcon } from '../../icons';
import { CustomToast } from './Toast.interface';
import { ToastWrapper } from './ToastWrapper';

export const InfoToast = (t: CustomToast) => {
  return (
    <ToastWrapper t={t} className="bg-white text-neutral-900">
      <div className="flex-1 w-0 p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0">
            {t.icon || (
              <InformationOutlineIcon className="text-neutral-900 w-7 h-7" />
            )}
          </div>
          <div className="ml-3 flex-1 mt-1">
            <p className="text-sm font-medium text-neutral-900">
              {t.title || 'Info'}
            </p>
            {t.description && (
              <p className="text-xs text-neutral-900">{t.description}</p>
            )}
          </div>
        </div>
      </div>
    </ToastWrapper>
  );
};
