import { Tag } from "../Tags/Tag";
export class User {
    static fromExtendedAccountDto(dto) {
        return new User(dto.avatar_url, dto.avatar_key, dto.background_url, dto.background_key, dto.cognito_id, dto.email, dto.first_name, dto.last_name, dto.description, dto.website_url, dto.website2_url, dto.website3_url, dto.youtube_url, dto.tiktok_url, dto.facebook_url, dto.instagram_url, dto.vimeo_url, (dto.tags || []).map((tagDto)=>Tag.fromTagDTO(tagDto)), dto.subscriptionsCount, dto.isSubscribed, dto.created_at, null);
    }
    static fromSearchAccountDto(dto) {
        return new User(dto.avatar_url, dto.avatar_key, dto.background_url, dto.background_key, dto.cognito_id, dto.email, dto.first_name, dto.last_name, dto.description, dto.website_url, dto.website2_url, dto.website3_url, dto.youtube_url, dto.tiktok_url, dto.facebook_url, dto.instagram_url, dto.vimeo_url, (dto.tags || []).map((tagDto)=>Tag.fromTagDTO(tagDto)), dto.subscriptionsCount, dto.isSubscribed, dto.created_at, dto.new_videos_count);
    }
    toJSON() {
        return {
            avatar_url: this.avatar_url,
            avatar_key: this.avatar_key,
            background_url: this.background_url,
            background_key: this.background_key,
            email: this.email,
            last_name: this.last_name,
            cognito_id: this.cognito_id,
            first_name: this.first_name,
            description: this.description || null,
            website_url: this.website_url || null,
            website2_url: this.website2_url || null,
            website3_url: this.website3_url || null,
            youtube_url: this.youtube_url || null,
            tiktok_url: this.tiktok_url || null,
            instagram_url: this.instagram_url || null,
            facebook_url: this.facebook_url || null,
            vimeo_url: this.vimeo_url || null,
            name: this.name,
            websiteHostname: this.websiteHostname,
            website2Hostname: this.website2Hostname,
            website3Hostname: this.website3Hostname,
            tags: this.tags.map((tag)=>tag.toJSON()),
            id: this.cognito_id,
            isSubscribed: this.isSubscribed,
            subscriptionsCount: this.subscriptionsCount,
            created_at: this.created_at,
            new_videos_count: this.new_videos_count
        };
    }
    get websiteHostname() {
        if (!this.website_url) return this.website_url;
        try {
            return new URL(this.website_url).hostname;
        } catch (e) {
            return null;
        }
    }
    get website2Hostname() {
        if (!this.website2_url) return this.website2_url;
        try {
            return new URL(this.website2_url).hostname;
        } catch (e) {
            return null;
        }
    }
    get website3Hostname() {
        if (!this.website3_url) return this.website3_url;
        try {
            return new URL(this.website3_url).hostname;
        } catch (e) {
            return null;
        }
    }
    get name() {
        return this.first_name || this.last_name ? "".concat(this.first_name, " ").concat(this.last_name).trim() : this.email;
    }
    get id() {
        return this.cognito_id;
    }
    constructor(avatar_url, avatar_key, background_url, background_key, cognito_id, email, first_name, last_name, description, website_url, website2_url, website3_url, youtube_url, tiktok_url, facebook_url, instagram_url, vimeo_url, tags, subscriptionsCount, isSubscribed, created_at, new_videos_count){
        this.avatar_url = avatar_url;
        this.avatar_key = avatar_key;
        this.background_url = background_url;
        this.background_key = background_key;
        this.cognito_id = cognito_id;
        this.email = email;
        this.first_name = first_name;
        this.last_name = last_name;
        this.description = description;
        this.website_url = website_url;
        this.website2_url = website2_url;
        this.website3_url = website3_url;
        this.youtube_url = youtube_url;
        this.tiktok_url = tiktok_url;
        this.facebook_url = facebook_url;
        this.instagram_url = instagram_url;
        this.vimeo_url = vimeo_url;
        this.tags = tags;
        this.subscriptionsCount = subscriptionsCount;
        this.isSubscribed = isSubscribed;
        this.created_at = created_at;
        this.new_videos_count = new_videos_count;
    }
}
