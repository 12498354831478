import React from 'react';
import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';

export interface DropdownProps {
  menuItems?: React.ReactNode[];
  children: React.ReactNode;
  className?: string;
  overlayClassName?: string;
}

export const Dropdown: React.FC<DropdownProps> = ({
  menuItems,
  children,
  className,
  overlayClassName,
}) => {
  return (
    <Menu
      as="div"
      className={classNames('relative inline-block text-left', className)}
    >
      <div>
        <Menu.Button>{children}</Menu.Button>
      </div>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={classNames(
            'absolute right-0 z-10 mt-2 py-1 w-[120px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
            overlayClassName
          )}
        >
          {menuItems?.map((item, index) => (
            <Menu.Item key={index}>{item}</Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
