import React from 'react';
import { IconProps } from '../Icons.interface';

export const InboxOutlineIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5 11H5.5M19.5 11C20.0304 11 20.5391 11.2107 20.9142 11.5858C21.2893 11.9609 21.5 12.4696 21.5 13V19C21.5 19.5304 21.2893 20.0391 20.9142 20.4142C20.5391 20.7893 20.0304 21 19.5 21H5.5C4.96957 21 4.46086 20.7893 4.08579 20.4142C3.71071 20.0391 3.5 19.5304 3.5 19V13C3.5 12.4696 3.71071 11.9609 4.08579 11.5858C4.46086 11.2107 4.96957 11 5.5 11M19.5 11V9C19.5 8.46957 19.2893 7.96086 18.9142 7.58579C18.5391 7.21071 18.0304 7 17.5 7M5.5 11V9C5.5 8.46957 5.71071 7.96086 6.08579 7.58579C6.46086 7.21071 6.96957 7 7.5 7M17.5 7V5C17.5 4.46957 17.2893 3.96086 16.9142 3.58579C16.5391 3.21071 16.0304 3 15.5 3H9.5C8.96957 3 8.46086 3.21071 8.08579 3.58579C7.71071 3.96086 7.5 4.46957 7.5 5V7M17.5 7H7.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
