import React from 'react';
import { IconProps } from '../Icons.interface';

export const LocationMarkerOutlineIcon: React.FC<IconProps> = ({
  className,
}) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.157 15.6567L13.914 19.8997C13.7284 20.0854 13.5081 20.2328 13.2656 20.3333C13.023 20.4339 12.7631 20.4856 12.5005 20.4856C12.238 20.4856 11.978 20.4339 11.7354 20.3333C11.4929 20.2328 11.2726 20.0854 11.087 19.8997L6.843 15.6567C5.72422 14.5379 4.96234 13.1124 4.65369 11.5606C4.34504 10.0087 4.50349 8.40022 5.10901 6.93844C5.71452 5.47665 6.7399 4.22725 8.05548 3.34821C9.37107 2.46918 10.9178 2 12.5 2C14.0822 2 15.6289 2.46918 16.9445 3.34821C18.2601 4.22725 19.2855 5.47665 19.891 6.93844C20.4965 8.40022 20.655 10.0087 20.3463 11.5606C20.0377 13.1124 19.2758 14.5379 18.157 15.6567V15.6567Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6213 12.1213C15.1839 11.5587 15.5 10.7956 15.5 10C15.5 9.20435 15.1839 8.44129 14.6213 7.87868C14.0587 7.31607 13.2956 7 12.5 7C11.7044 7 10.9413 7.31607 10.3787 7.87868C9.81607 8.44129 9.5 9.20435 9.5 10C9.5 10.7956 9.81607 11.5587 10.3787 12.1213C10.9413 12.6839 11.7044 13 12.5 13C13.2956 13 14.0587 12.6839 14.6213 12.1213Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
