import React from 'react';
import { IconProps } from '../Icons.interface';

export const InstagramOutlineIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66667 2.5C4.36917 2.5 2.5 4.36917 2.5 6.66667L2.5 13.3333C2.5 15.6308 4.36917 17.5 6.66667 17.5H13.3333C15.6308 17.5 17.5 15.6308 17.5 13.3333V6.66667C17.5 4.36917 15.6308 2.5 13.3333 2.5L6.66667 2.5ZM6.66667 4.16667L13.3333 4.16667C14.7117 4.16667 15.8333 5.28833 15.8333 6.66667V13.3333C15.8333 14.7117 14.7117 15.8333 13.3333 15.8333H6.66667C5.28833 15.8333 4.16667 14.7117 4.16667 13.3333L4.16667 6.66667C4.16667 5.28833 5.28833 4.16667 6.66667 4.16667ZM14.1667 5C13.9457 5 13.7337 5.0878 13.5774 5.24408C13.4211 5.40036 13.3333 5.61232 13.3333 5.83333C13.3333 6.05435 13.4211 6.26631 13.5774 6.42259C13.7337 6.57887 13.9457 6.66667 14.1667 6.66667C14.3877 6.66667 14.5996 6.57887 14.7559 6.42259C14.9122 6.26631 15 6.05435 15 5.83333C15 5.61232 14.9122 5.40036 14.7559 5.24408C14.5996 5.0878 14.3877 5 14.1667 5ZM10 5.83333C7.7025 5.83333 5.83333 7.7025 5.83333 10C5.83333 12.2975 7.7025 14.1667 10 14.1667C12.2975 14.1667 14.1667 12.2975 14.1667 10C14.1667 7.7025 12.2975 5.83333 10 5.83333ZM10 7.5C11.3783 7.5 12.5 8.62167 12.5 10C12.5 11.3783 11.3783 12.5 10 12.5C8.62167 12.5 7.5 11.3783 7.5 10C7.5 8.62167 8.62167 7.5 10 7.5Z"
      fill="currentColor"
    />
  </svg>
);
