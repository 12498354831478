import React from 'react';
import { IconProps } from '../Icons.interface';

export const FacebookOutlineIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.16667 2.5C3.2475 2.5 2.5 3.2475 2.5 4.16667L2.5 15.8333C2.5 16.7525 3.2475 17.5 4.16667 17.5H9.68424H12.0117L15.8333 17.5C16.7525 17.5 17.5 16.7525 17.5 15.8333L17.5 4.16667C17.5 3.2475 16.7525 2.5 15.8333 2.5L4.16667 2.5ZM4.16667 4.16667L15.8333 4.16667L15.835 15.8333L12.0117 15.8333L12.0117 12.5293L13.9827 12.5293L14.2757 10.2588H12.0247V8.81185C12.0247 8.15518 12.2077 7.71159 13.1494 7.71159H14.3392L14.3392 5.68522C13.76 5.62605 13.1785 5.59729 12.596 5.59896C10.8677 5.59896 9.68424 6.65383 9.68424 8.59049V10.262L7.73112 10.262L7.73112 12.5326H9.68424V15.8333L4.16667 15.8333L4.16667 4.16667Z"
      fill="currentColor"
    />
  </svg>
);
