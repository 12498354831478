import React from 'react';
import classNames from 'classnames';

export type DividerProps = {
  className?: string;
};

export const Divider = ({ className }: DividerProps) => {
  return (
    <div
      className={classNames(
        'w-full bg-neutral-100 h-[2px] min-h-[2px]',
        className
      )}
    />
  );
};
