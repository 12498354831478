import React from 'react';
import { IconProps } from '../Icons.interface';

export const SearchOutlineIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 16L11.379 13.121M11.379 13.121C11.6567 13.4033 11.9876 13.6279 12.3525 13.7817C12.7174 13.9355 13.1092 14.0156 13.5052 14.0172C13.9012 14.0189 14.2936 13.9421 14.6599 13.7914C15.0261 13.6406 15.3588 13.4188 15.6388 13.1388C15.9189 12.8588 16.1408 12.5262 16.2916 12.16C16.4425 11.7938 16.5193 11.4014 16.5177 11.0054C16.5162 10.6094 16.4362 10.2176 16.2825 9.85265C16.1287 9.48768 15.9043 9.15677 15.622 8.879C15.0579 8.32389 14.2973 8.01417 13.5059 8.0173C12.7145 8.02043 11.9564 8.33615 11.3967 8.89571C10.837 9.45526 10.5211 10.2133 10.5178 11.0047C10.5145 11.7961 10.824 12.5568 11.379 13.121ZM21.5 12C21.5 13.1819 21.2672 14.3522 20.8149 15.4442C20.3626 16.5361 19.6997 17.5282 18.864 18.364C18.0282 19.1997 17.0361 19.8626 15.9442 20.3149C14.8522 20.7672 13.6819 21 12.5 21C11.3181 21 10.1478 20.7672 9.05585 20.3149C7.96392 19.8626 6.97177 19.1997 6.13604 18.364C5.30031 17.5282 4.63738 16.5361 4.18508 15.4442C3.73279 14.3522 3.5 13.1819 3.5 12C3.5 9.61305 4.44821 7.32387 6.13604 5.63604C7.82387 3.94821 10.1131 3 12.5 3C14.8869 3 17.1761 3.94821 18.864 5.63604C20.5518 7.32387 21.5 9.61305 21.5 12Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
