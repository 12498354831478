import React from 'react';
import classNames from 'classnames';

export function Navigation(props: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <nav
      className={classNames(
        'flex flex-col px-4 py-12 bg-white md:items-center md:flex-row md:py-0 md:gap-4 lg:gap-2 xl:gap-8',
        props.className
      )}
    >
      {props.children}
    </nav>
  );
}

export function NavigationItem(props: {
  isActive: boolean;
  children: React.ReactNode;
  icon: React.ReactNode;
  className?: string;
  disabled?: boolean;
}) {
  return (
    <div
      className={classNames(
        {
          'bg-neutral-100 lg:bg-transparent': props.isActive && !props.disabled,
        },
        'group flex items-center',
        props.className
      )}
    >
      <div
        className={classNames('mr-1 flex-shrink-0 text-neutral-500', {
          'text-neutral-300': props.disabled,
        })}
        aria-hidden="true"
      >
        {props.icon}
      </div>
      {props.children}
    </div>
  );
}
