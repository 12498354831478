import { useQuery } from "@tanstack/react-query";
import { User } from "../User";
import { AccountApi } from "../Api";
export const useHeader = ()=>{
    const userApi = new AccountApi();
    const useCurrentUserProfileQuery = ()=>useQuery([
            "userProfile",
            "me"
        ], async ()=>await userApi.getCurrentAccount().then(User.fromExtendedAccountDto));
    return {
        useCurrentUserProfileQuery
    };
};
