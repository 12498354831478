import React from 'react';
import { CircleCheckIcon } from '../../icons';
import { CustomToast } from './Toast.interface';
import { ToastWrapper } from './ToastWrapper';

export const SuccessToast = (t: CustomToast) => {
  return (
    <ToastWrapper t={t} className="bg-white text-primary-500">
      <div className="flex-1 w-0 p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0">
            {t.icon || <CircleCheckIcon className="text-primary-500 w-7 h-7" />}
          </div>
          <div className="ml-3 flex-1 mt-1">
            <p className="text-sm font-medium text-primary-500">
              {t.title || 'Success'}
            </p>
            {t.description && (
              <p className="text-xs text-neutral-600">{t.description}</p>
            )}
          </div>
        </div>
      </div>
    </ToastWrapper>
  );
};
