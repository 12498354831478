import React from 'react';
import { IconProps } from '../Icons.interface';

export const TiktokOutlineIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M5 2.5C3.62919 2.5 2.5 3.62919 2.5 5L2.5 15C2.5 16.3708 3.62919 17.5 5 17.5L15 17.5C16.3708 17.5 17.5 16.3708 17.5 15L17.5 5C17.5 3.62919 16.3708 2.5 15 2.5L5 2.5ZM5 4.16667L15 4.16667C15.4709 4.16667 15.8333 4.52914 15.8333 5L15.8333 15C15.8333 15.4709 15.4709 15.8333 15 15.8333L5 15.8333C4.52914 15.8333 4.16667 15.4709 4.16667 15L4.16667 5C4.16667 4.52914 4.52914 4.16667 5 4.16667ZM10 5.83333L10 11.6667C10 12.1375 9.63752 12.5 9.16667 12.5C8.69581 12.5 8.33333 12.1375 8.33333 11.6667C8.33333 11.1958 8.69581 10.8333 9.16667 10.8333V9.16667C7.79586 9.16667 6.66667 10.2959 6.66667 11.6667C6.66667 13.0375 7.79586 14.1667 9.16667 14.1667C10.5375 14.1667 11.6667 13.0375 11.6667 11.6667V8.52702C12.1801 8.89278 12.7159 9.16667 13.3333 9.16667V7.5C13.2939 7.5 12.7189 7.31745 12.3177 6.96777C11.9165 6.6181 11.6667 6.17916 11.6667 5.83333H10Z"
      fill="currentColor"
    />
  </svg>
);
