import { HttpClient } from "@parkschannel/next-utils";
import { Paginated } from "@parkschannel/contracts";
export class AccountApi {
    getAccounts(params) {
        return this.client.get("".concat(this.baseUrl, "/search"), {
            params
        }, false);
    }
    getAccount(accountId) {
        return this.client.get("".concat(this.baseUrl, "/").concat(accountId), {}, false);
    }
    getCurrentAccount() {
        return this.client.get("".concat(this.baseUrl, "/me"));
    }
    getMyVideos(params) {
        return this.client.get("".concat(this.baseUrl, "/me/videos"), {
            params: {
                page: params.page,
                limit: params.limit,
                "filter.status": "$eq:".concat(params.search)
            }
        });
    }
    getMyStickers(params) {
        return this.client.get("".concat(this.baseUrl, "/me/stickers"), {
            params: {
                page: params.page,
                limit: params.limit
            }
        });
    }
    getMySubscribed(params) {
        return this.client.get("".concat(this.baseUrl, "/me/subscribed"), {
            params: {
                page: params.page,
                limit: params.limit
            }
        });
    }
    getMySubscribers(params) {
        return this.client.get("".concat(this.baseUrl, "/me/subscribers"), {
            params: {
                page: params.page,
                limit: params.limit
            }
        });
    }
    getMyResourceCount() {
        return this.client.get("".concat(this.baseUrl, "/me/resource-counts"));
    }
    getUserVideos(userId, params) {
        return this.client.get("".concat(this.baseUrl, "/").concat(userId, "/videos"), {
            params: {
                page: params.page,
                limit: params.limit
            }
        }, false);
    }
    getUserResourceCount(accountId) {
        return this.client.get("".concat(this.baseUrl, "/").concat(accountId, "/resource-counts"));
    }
    subscribe(accountId) {
        return this.client.put("".concat(this.baseUrl, "/").concat(accountId, "/subscriptions"));
    }
    getUserStickers(userId, params) {
        return this.client.get("".concat(this.baseUrl, "/").concat(userId, "/stickers"), {
            params: {
                page: params.page,
                limit: params.limit
            }
        }, false);
    }
    updateAccount(accountId, accountData) {
        return this.client.put("".concat(this.baseUrl, "/").concat(accountId), {
            ...accountData
        });
    }
    changePassword(accountId, data) {
        return this.client.put("".concat(this.baseUrl, "/").concat(accountId, "/password"), {
            ...data
        });
    }
    deleteAccount(accountId) {
        return this.client.delete("".concat(this.baseUrl, "/").concat(accountId));
    }
    createAccount(newAccount) {
        return this.client.post("".concat(this.baseUrl, "/").concat(newAccount.cognito_id), newAccount);
    }
    constructor(client = new HttpClient()){
        this.client = client;
        this.baseUrl = "/api/accounts";
    }
}
export class PaginatedWithFaucetCounts extends Paginated {
}
export class PaginatedSubscriptionsWithFaucetCounts extends Paginated {
}
