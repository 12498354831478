export function getEnvVariables() {
  const API_URL = process.env['API_URL'];
  const WORDPRESS_URL = process.env['WORDPRESS_URL'];
  const PAGE_URL = process.env['PAGE_URL'];
  const MAP_BOX_TOKEN = process.env['MAP_BOX_TOKEN'];
  const LOG_LEVEL = process.env['LOG_LEVEL'];
  // TODO: Because of Next Prerendering this requires env variables to be available during build time.
  // assertIsNonEmptyString(API_URL);
  // assertIsNonEmptyString(PAGE_URL);

  return {
    API_URL: API_URL || '',
    PAGE_URL: PAGE_URL || '',
    MAP_BOX_TOKEN: MAP_BOX_TOKEN || '',
    WORDPRESS_URL: WORDPRESS_URL || '',
    LOG_LEVEL: LOG_LEVEL,
  };
}
