import { useEffect } from "react";
export const useBlockBodyScroll = (val)=>{
    useEffect(()=>{
        if (val) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }
        return ()=>{
            document.body.style.overflow = "unset";
        };
    }, [
        val
    ]);
};
