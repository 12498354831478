import React, { ButtonHTMLAttributes } from 'react';
import { Button, ButtonProps, ButtonSize, ButtonVariant } from '../Button';
import classNames from 'classnames';

export interface IconButtonProps {
  icon: JSX.Element;
  position?: 'left' | 'right';
}

export const IconButton: React.FC<
  IconButtonProps &
    Partial<ButtonProps> &
    ButtonHTMLAttributes<HTMLButtonElement>
> = ({
  icon,
  position = 'right',
  size = 'md',
  variant,
  children,
  className,
  ...rest
}) => {
  return (
    <Button
      className={classNames(
        {
          '!px-2 !py-2': !children,
        },
        className
      )}
      size={size}
      variant={variant}
      {...rest}
    >
      <div
        className={classNames('flex items-center ', buttonGap(size, variant), {
          'flex-row': position === 'right',
          'flex-row-reverse': position === 'left',
        })}
      >
        {children}
        {icon}
      </div>
    </Button>
  );
};

function buttonGap(size: ButtonSize, variant?: ButtonVariant) {
  if (variant === 'text') return 'gap-1';
  switch (size) {
    case 'sm':
      return 'gap-2';
    default:
      return 'gap-4';
  }
}
