import Client from "@imgix/js-core";
export class ImgixClient {
    get client() {
        return this._client;
    }
    buildUrl(path, params) {
        return this._client.buildURL(path, params);
    }
    constructor(domain, secureToken){
        this._client = new Client({
            domain: "".concat(domain, ".imgix.net"),
            secureURLToken: secureToken
        });
    }
}
