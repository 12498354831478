import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { getEnvVariables } from './env';

export class NotFoundError extends Error {}

export class HttpClient {
  private readonly client: AxiosInstance;
  private readonly options: { accessToken?: string; url?: string };

  constructor(options: { accessToken?: string; url?: string } = {}) {
    this.options = options;
    this.client = axios.create({
      baseURL: this.getBaseUrl(),
      headers: this.getHeaders(),
    });
    this.client.interceptors.response.use(
      (response) => response,
      (error) => {
        if (isAxiosResponseError(error) && error.response.status === 404) {
          throw new NotFoundError(`Api returned 404: ${error.request.url}`);
        }

        return Promise.reject(error);
      }
    );
  }

  async get<ReturnType>(
    url: string,
    config?: AxiosRequestConfig,
    requiresAuthentication = true
  ): Promise<ReturnType> {
    const { data } = await this.client.get<ReturnType>(url, {
      ...(config || {}),
      headers: {
        ...(config?.headers || {}),
        'x-client-requires-authentication': requiresAuthentication,
      },
    });
    return data;
  }

  async post<RequestDataType, ReturnType = void>(
    url: string,
    requestData?: RequestDataType,
    config?: AxiosRequestConfig
  ): Promise<ReturnType> {
    const { data } = await this.client.post<
      RequestDataType,
      AxiosResponse<ReturnType>
    >(url, requestData, config);

    return data;
  }

  async put<RequestDataType, ReturnType = void>(
    url: string,
    requestData?: RequestDataType,
    config?: AxiosRequestConfig
  ): Promise<ReturnType> {
    const { data } = await this.client.put<
      RequestDataType,
      AxiosResponse<ReturnType>
    >(url, requestData, config);

    return data;
  }

  async patch<RequestDataType, ReturnType = void>(
    url: string,
    requestData: RequestDataType,
    config?: AxiosRequestConfig
  ): Promise<ReturnType> {
    const { data } = await this.client.patch<
      RequestDataType,
      AxiosResponse<ReturnType>
    >(url, requestData, config);
    return data;
  }

  async delete<RequestDataType, ReturnType = void>(
    url: string,
    requestData?: RequestDataType
  ): Promise<ReturnType> {
    const { data } = await this.client.delete<
      RequestDataType,
      AxiosResponse<ReturnType>
    >(url, { data: requestData });

    return data;
  }

  private getHeaders(): Record<string, string> {
    return {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-Request-Id': Math.random().toString(),
      ...(this.options.accessToken
        ? { Authorization: `Bearer ${this.options.accessToken}` }
        : {}),
    };
  }

  getBaseUrl(): string {
    return this.options.url || getEnvVariables().PAGE_URL;
  }
}

function isAxiosResponseError(error: unknown): error is AxiosResponseError {
  return typeof error === 'object' && error !== null && 'response' in error;
}

type AxiosResponseError = AxiosError & { response: AxiosResponse };
