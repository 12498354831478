import React, { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

export type ButtonVariant = 'default' | 'base' | 'outline' | 'text' | 'danger';
export type ButtonSize = 'md' | 'sm';

export type ButtonProps = {
  children: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  variant?: ButtonVariant;
  className?: string;
  size?: ButtonSize;
};

export const Button = ({
  children,
  onClick,
  className,
  variant = 'default',
  disabled,
  size = 'md',
  ...rest
}: ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      {...rest}
      className={classNames(
        `rounded-xl leading-4 font-medium transition-all duration-200`,
        buttonPadding(size),
        buttonFontSize(size),
        {
          'bg-neutral-50 drop-shadow-sm text-neutral-200': disabled,
          'bg-green1 hover:bg-gradient-to-r hover:from-primary-500 hover:to-primary-500 drop-shadow hover:shadow-xl text-white':
            variant === 'default' && !disabled,
          'bg-white border-[2px] border-green-500 drop-shadow text-neutral-600 hover:border-green-400 hover:shadow-lg':
            variant === 'outline' && !disabled,
          'text-neutral-700 drop-shadow-sm hover:shadow-lg bg-white':
            variant === 'base' && !disabled,
          'text-neutral-600 hover:bg-neutral-50 bg-transparent':
            variant === 'text' && !disabled,
          'bg-red-600 border-[2px] border-red-700 text-white hover:bg-red-500 hover:border-red-600 drop-shadow-sm hover:shadow-lg':
            variant === 'danger' && !disabled,
        },
        className
      )}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export function buttonPadding(size: ButtonSize) {
  switch (size) {
    case 'sm':
      return 'py-2 px-4';
    default:
      return 'py-3 px-6';
  }
}

export function buttonFontSize(size: ButtonSize) {
  switch (size) {
    case 'sm':
      return 'text-sm';
    default:
      return 'text-base';
  }
}
