export class Tag {
    static fromTagDTO(dto) {
        return new Tag(dto.id, dto.name);
    }
    toJSON() {
        return {
            id: this.id,
            name: this.name
        };
    }
    constructor(id, name){
        this.id = id;
        this.name = name;
    }
}
