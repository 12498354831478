import React, { memo } from 'react';
import classNames from 'classnames';
import isEqual from 'lodash.isequal';

type TypographyVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'bodyXL'
  | 'bodyLarge'
  | 'bodyBase'
  | 'bodySmall'
  | 'captionBase'
  | 'captionSmall';

type TypographyComponent =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'span';

export interface TypographyProps {
  component?: TypographyComponent;
  variant?: TypographyVariant;
  className?: string;
  children: React.ReactNode;
  id?: string;
}

export const Typography = memo(
  React.forwardRef<
    HTMLHeadingElement | HTMLParagraphElement | HTMLSpanElement,
    TypographyProps
  >(({ component, id, variant = 'h1', children, className }, ref) => {
    const Component = component || componentMapping(variant);

    return (
      <Component
        ref={ref as any}
        id={id}
        className={classNames(variantStyles(variant || component), className)}
      >
        {children}
      </Component>
    );
  }),
  isPropsEqual
);

function componentMapping(variant: TypographyVariant) {
  switch (variant) {
    case 'bodyBase':
    case 'bodyLarge':
    case 'bodySmall':
    case 'bodyXL':
      return 'p';
    case 'captionBase':
    case 'captionSmall':
      return 'span';
    default:
      return variant;
  }
}

function variantStyles(variant: TypographyVariant) {
  switch (variant) {
    case 'h1':
      return 'font-["ITC_Officina_Sans_Std"] text-[32px] leading-[40px] font-bold lg:text-[48px] lg:leading-[56px]';
    case 'h2':
      return 'font-["ITC_Officina_Sans_Std"] text-[28px] leading-[36px] font-bold lg:text-[40px] lg:leading-[50px]';
    case 'h3':
      return 'font-["ITC_Officina_Sans_Std"] text-[24px] leading-[32px] font-bold lg:text-[32px] lg:leading-[42px]';
    case 'h4':
      return 'font-["ITC_Officina_Sans_Std"] text-[18px] leading-[22px] font-bold lg:text-[24px] lg:leading-[32px]';
    case 'h5':
      return 'font-["ITC_Officina_Sans_Std"] text-[16px] leading-[20px] font-bold lg:text-[20px] lg:leading-[26px]';
    case 'h6':
      return 'font-["ITC_Officina_Sans_Std"] text-[14px] leading-[18px] font-bold lg:text-[16px] lg:leading-[22px]';
    case 'bodyBase':
      return 'font-["Gotham"] text-[11px] leading-[16px] lg:text-[12px] lg:leading-[18px]';
    case 'bodySmall':
      return 'font-["Gotham"] text-[11px] leading-[16px]';
    case 'bodyLarge':
      return 'font-["Gotham"] text-[12px] leading-[18px] lg:text-[14px] lg:leading-[22px]';
    case 'bodyXL':
      return 'font-["Gotham"] text-[14px] leading-[18px] lg:text-[16px] lg:leading-[22px]';
    case 'captionSmall':
      return 'font-["Gotham"] text-[10px] leading-[15px]';
    case 'captionBase':
      return 'font-["Gotham"] text-[12px] leading-[18px]';
  }
}

function isPropsEqual(prevProps: TypographyProps, nextProps: TypographyProps) {
  return isEqual(prevProps, nextProps);
}
